<template>
  <b-card-code>
    <div v-if="$can('create','Waiver')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add new') }}
        </b-button>
    </div>

    <div class="custom-search d-flex justify-content-start">
      
        <b-col md="3">
          <b-card-text>
            <span>Select Class </span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="class" rules="">
            <select name="classes_id" v-model.number="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                {{ cls.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="3">
          <b-card-text>
            <span>Select Institution</span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="wing" rules="">
            <select name="wing_id" v-model.number="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(wing, key) in wings" :value="wing.id" :key="key">
                {{ wing.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="3">
          <b-card-text>
            <span>Select Group</span>
          </b-card-text>
          <validation-provider #default="{ errors }" name="student group" rules="">
            <select name="student_group_id" v-model.number="student_group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
              <option :value="null">Select One</option>
              <option v-for="(grp, key) in student_groups" :value="grp.id" :key="key">
                {{ grp.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
    </div>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
   <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div> 

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="student_waivers"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>

         <span v-else-if="props.column.field === 'nick_name'">
          {{ studentNameByID(props.row.id) }}
        </span>

        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_waiver_type_id'">
          {{ waiverTypeByID(props.row.student_waiver_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_fee_type_id'">
          {{ studentFeeTypeByID(props.row.student_fee_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'month_year'">
          {{ get_month_name_by_id(props.row.month_year) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'approval_status'">
          <b-badge :variant="statusVariant(props.row.approval_status)">
            {{ status2[props.row.approval_status] }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >

              <template v-if="props.row.approval_status==0">
                <!-- modal login button -->
                <b-button v-if="(findAccess(props.row.id) || user.role_id===1)" variant="primary" @click="approve(props.row.id)">
                  <feather-icon class="text-body " icon="CheckIcon" size="15" />
                </b-button>
                <b-button v-if="(findAccess(props.row.id) || user.role_id===1)" variant="danger" @click="decliend(props.row.id)">
                  <feather-icon class="text-body " icon="CrosshairIcon" size="15" />
                </b-button>
                <b-button v-if="props.row.approvalable.length<1 && user.role_id===1" variant="secondary" @click="showModal2(props.row)">
                  <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
                </b-button>
                <b-button v-if="findAccess(props.row.id)" variant="secondary" @click="showModal2(props.row)">
                  <feather-icon class="text-body " icon="GitBranchIcon" size="15" />
                </b-button>
                
              </template>
              <b-button  @click="selectedRow(props.row)"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-modal.modal-login
                          variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button  variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_or_update">
          <b-row>
            <template v-if="model_mode==='add'">
              <b-col md="4">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Student No') }}</span>
                  </b-card-text>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('student id')"
                      rules="required"
                  >
                    <b-form-input
                        v-model="student_id"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('Student No')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-button class="mt-3"
                          variant="primary"
                          type="button"
                          @click="findStudent"
                >{{ $t('Find') }}</b-button>
              </b-col>
            </template>

            <template v-if="student.id">
              <b-col md="12" class="mb-2">
                <span>{{ $t('Name') }}: </span>{{student.userable?student.userable.name:''}}<br>
                <span>{{ $t('Class') }}: </span>{{classByID(student.classes_id)}}<br>
                <span>{{ $t('Student Group') }}: </span>{{student_groupByID(student.student_group_id)}}<br>
                <span>{{ $t('Academic Year') }}: </span>{{academic_yearByID(student.academic_year_id)}}<br>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>Waiver Type </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('waiver type')"
                    rules="required"
                >
                  <select name="student_waiver_type_id" v-model="student_waiver_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(subject,key) in active_student_waiver_types" :value="subject.id" :key="key">{{subject.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-card-text>
                  <span>{{ $t('Select Student Fee type') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('student fee type')"
                    rules="required"
                >
                  <select name="student_fee_type_id" v-model="student_fee_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(feeType, key) in active_student_fee_types" :value="feeType.id" :key="key">{{feeType.name}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- <b-col md="4">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Waiver month') }}</span>
                  </b-card-text>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('waiver month')"
                      rules="required"
                  >
                    <b-form-input type="month"
                                  v-model="month_year"
                                  name="month_year"
                                  :state="errors.length > 0 ? false:null"
                                  :placeholder="$t('waiver month')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <b-col md="4">
              <b-form-group>
                <b-card-text>
                   <span>{{ $t('Waiver month') }}</span>
                </b-card-text>
               <validation-provider
                      #default="{ errors }"
                      :name="$t('waiver month')"
                      rules="required"
                  >
                  
                 <select name="month_year" v-model="month_year" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                 <option :value="null">Select One</option>
                 <option  :value="1">January</option>
                 <option  :value="2">February</option>
                 <option  :value="3">March</option>
                 <option  :value="4">April</option>
                 <option  :value="5">May</option>
                 <option  :value="6">June</option>
                 <option  :value="7">July</option>
                 <option  :value="8">August</option>
                 <option  :value="9">September</option>
                 <option  :value="10">October</option>
                 <option  :value="11">November</option>
                 <option  :value="12">December</option>
                </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>



              <b-col md="4">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Waiver amount') }}</span>
                  </b-card-text>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('waiver amount')"
                      rules="required"
                  >
                    <b-form-input
                        v-model.number="amount"
                        name="amount"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('waiver amount')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <b-card-text>
                    <span>{{ $t('Note') }}</span>
                  </b-card-text>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('note')"
                      rules="required"
                  >
                    <b-form-textarea
                        v-model.number="note"
                        name="note"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('note')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2" v-if="model_mode === 'add'">
                <b-card-text>
                  <span>{{ $t('Need approval ?') }}</span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('need approval')"
                    rules="required"
                >
                  <b-form-radio-group
                      v-model.number="approval_status"
                      :options="options"
                      stacked
                      name="approval_status"
                  >
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="4" v-if="model_mode=='add' && approval_status===0">
                <b-card-text>
                  <span>Select Employee </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select v-model="employee_id" name="employee_id" class="form-control"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                    <option  :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :key="key" :id="employee.id" :value="employee.id">{{ employeeNameById(employee.id) +' '+ employeeIdById(employee.id)}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- submit button -->
              <b-col>
                <b-button class="mt-4"
                          variant="primary"
                          type="submit"
                          @click.prevent="assignWaiver"
                >
                  Submit
                </b-button>
              </b-col>
            </template>

          </b-row>
        </form>
      </validation-observer>
    </b-modal>
    <b-modal ref="my-modal2"
             hide-footer size="lg"
             :title="'Foreword Information'"
    >
      <b-row>
        <b-col md="12">
          <b-table-simple hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Employee ID</b-th>
              <b-th class="text-center"  :colspan="1">Employee Name</b-th>
              <b-th class="text-center"  :colspan="1">Comment</b-th>
              <b-th class="text-center"  :colspan="1">Status</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(grade,k) in selected_row.approvalable" :key="k">
                <b-th class="text-center">{{ employeeIdById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ employeeNameById(grade.employee_id) }}</b-th>
                <b-th class="text-center">{{ grade.comment }}</b-th>
                <b-th class="text-center">{{ status3[parseInt(grade.status)] }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form ref="leave_request">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="employee"
                    rules="required"
                >
                  <select name="employee_id" v-model="employee_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                    <option  :value="null">Select One</option>
                    <option v-for="(employee, key) in employees" :value="employee.id" :key="key">{{employeeNameById(employee.id)}}- {{employee.employee_id}}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required"
                >
                  <b-form-textarea
                      v-model="comment"
                      name="comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="comment"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="forward"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal,BRow, BCol,BFormRadioGroup,BCardText,BFormTextarea,
  BForm,BTableSimple,BThead,BTr,BTh,BTbody,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'StudentWaiver',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BFormRadioGroup,BCardText,
    BPagination,BFormTextarea,
    BFormGroup,BForm,BTableSimple,BThead,BTr,BTh,BTbody,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      approval_status:1,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      classes_id: null,
      wing_id: null,
      student_group_id: null,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Student No.',
          field: 'student.cadet_no',
        },
        {
          label: 'Name',
          field: 'student.nick_name',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Waiver Type',
          field: 'student_waiver_type_id',
        },
        {
          label: 'Fee Type',
          field: 'student_fee_type_id',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Months',
          field: 'month_year',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        // {
        //   label: 'Status',
        //   field: 'approval_status',
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      options:{
        0:'yes',
        1:'No'
      },
      status2: {
        0: 'pending',
        1: 'approved',
        2: 'canceled',
      },
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      employee_id:null,
      comment:'',
      status3:{
        0:'Pending',
        1:'Approved',
        2:'Foreword',
        3:'Declined'
      },
      student_id:null,
      student_waiver_type_id:null,
      student_fee_type_id:null,
      amount:0,
      note:'',
      month_year:'',
      student:{},
      columns10: [
        {
          label: 'Student Id',
          field: 'StudentId',
        },
        {
          label: 'Class',
          field: 'Classs',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Waiver Type',
          field: 'WaiverType',
        },
        {
          label: 'Fee Type',
          field: 'FeeType',
        },
        {
          label: 'Amount',
          field: 'Amount',
        },
        {
          label: 'Months',
          field: 'Months',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods:{
    get_month_name_by_id(id){
            let month_name = "";
            if(parseInt(id) == 1){
                month_name = "January";
            }
            else if(parseInt(id) == 1){
                month_name = "January";
            }
            else if(parseInt(id) == 2){
                month_name = "February";
            }
            else if(parseInt(id) == 2){
                month_name = "March";
            }
            else if(parseInt(id) == 4){
                month_name = "April";
            }
            else if(parseInt(id) == 5){
                month_name = "May";
            }
            else if(parseInt(id) == 6){
                month_name = "June";
            }
            else if(parseInt(id) == 7){
                month_name = "July";
            }
            else if(parseInt(id) == 8){
                month_name = "August";
            }
            else if(parseInt(id) == 9){
                month_name = "September";
            }
            else if(parseInt(id) == 10){
                month_name = "October";
            }
            else if(parseInt(id) == 11){
                month_name = "November";
            }
            else if(parseInt(id) == 12){
                month_name = "december";
            }

            return month_name;
        },
    selectedRow(row){
      this.selected_row=row;
      this.student=row.student;
      this.amount=row.amount;
      this.student_waiver_type_id=row.student_waiver_type_id;
      this.student_fee_type_id=row.student_fee_type_id;
      this.note=row.note;
      this.month_year=row.month_year;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    assignWaiver() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update);
          if (this.model_mode ==='add'){
            data.append('student_id',this.student.id);
            apiCall.post('/student/waiver/store',data).then((response)=>{
              if (response.data.status ==='success'){
                this.hideModal();
                this.$store.commit('ADD_NEW_STUDENT_WAIVER',response.data.data);
                this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
                this.$toaster.success(response.data.message);
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/student/waiver/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status ==='success'){
                this.hideModal();
                this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
                this.$toaster.success(response.data.message);
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    approvalStatusChange(id,status,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/approve${id}/orCancel${status}`).then((response)=>{
            this.$store.commit('APPROVAL_STATUS_CHANGE_STUDENT_WAIVER',[index,status]);
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    statusChange(id,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/type/status/change${id}`).then((response)=>{
            this.$store.commit('STATUS_CHANGE_STUDENT_WAIVER_TYPE',index);
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    remove(id,index){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/student/waiver/remove${id}`).then((response)=>{
              this.$store.commit('REMOVE_STUDENT_WAIVER',index);
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your date is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.student= {};
      this.amount=0;
      this.student_id=null;
      this.student_waiver_type_id=null;
      this.student_fee_type_id=null;
      this.note='';
      this.month_year=null;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    findStudent(){
      apiCall.get(`/find/single/student/byCadetNo${this.student_id}`).then((response)=>{
        if (response.data.status==='success') {
          this.student=response.data.student;
        }
        else{
          this.$toaster.error(response.data.message);
        } 
      }).catch((error)=>{
        this.$toaster.error(error);
      })
    },
    showModal2(row) {
      this.selected_row=row;
      this.$refs['my-modal2'].show();
    },
    hideModal2() {
      this.$refs['my-modal2'].hide();
    },
    decliend(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to declined this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, declined it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/declined${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
            this.$swal({
              icon: 'success',
              title: 'Status Change!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    forward(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.leave_request);
          apiCall.post(`/student/waiver/forward${this.selected_row.id}`,data).then((response)=>{
            this.hideModal2();
            this.$toaster.success(response.data.message);
            this.selected_row={};
            this.employee_id=null;
            this.comment='';
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
          }).catch((error)=>{
            if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findAccess(id){
      let waiverList = this.student_waivers.find(item=>item.id===parseInt(id));
      if(waiverList){
        if(waiverList.approvalable.length < 1){
          return false;
        }
        else{
          return waiverList.approvalable[waiverList.approvalable.length -1].employee_id===this.user.userable.id;
        }
      }
      else{
        return false;
      }
    },
    /*
    *
    * method for approve item
    * */
    approve(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to approve!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/student/waiver/approve${id}`).then((response)=>{
            this.$toaster.success(response.data.message);
            this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    pdfGenerate() {
      let title = "Wiavers";
      let headers = ['StudentId', 'Classs', 'StudentGroup','WaiverType', 'FeeType','Amount','Months','AcademicYear' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(headers));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'waiver.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        2 : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    student_waivers(){
      let waiverItems = this.$store.getters.student_waivers;
      if (this.classes_id) {
        waiverItems = waiverItems.filter((item) => item.classes_id === this.classes_id);
      }

      if (this.wing_id) {
        waiverItems = waiverItems.filter((item) => item.wing_id === this.wing_id);
      }

      if (this.student_group_id) {
        waiverItems = waiverItems.filter((item) => item.student_group_id === this.student_group_id);
      }

      return waiverItems;
    },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    },
    ...mapGetters(['academic_years','academic_yearByID','classes','classByID','student_groups','student_groupByID', 'cadetIdByID',
      'student_waiver_types','waiverTypeByID','active_student_waiver_types','student_fee_types','studentFeeTypeByID',
      'active_student_fee_types','employees','employeeIdById','employeeNameById', 'studentNameByID',  'wings']),
      getData() {
      return this.student_waivers.map(item => ({
        StudentId: item.student_id ? this.cadetIdByID(item.student.student_id) : '',
        Classs: item.classes_id ? this.classByID(item.classes_id) : '',
        StudentGroup: item.student ? this.student_groupByID(item.student.student_group_id) : '',
        WaiverType: item.student_waiver_type_id ? this.waiverTypeByID(item.student_waiver_type_id) : '',
        FeeType: item.student_fee_type_id ? this.studentFeeTypeByID( item.student_fee_type_id) : '',
        Amount: item.amount,
        Months: item.month_year,
        AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
        Status: item.approval_status == 1 ? 'Approved' : (item.approval_status == 2 ? 'Canceled' : (!item.approval_status ? 'Pending' : '')),
      }));
    }
  },
  created() {
    if(this.student_waivers.length <1) this.$store.dispatch('GET_ALL_STUDENT_WAIVER');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.student_waiver_types.length <1) this.$store.dispatch('GET_ALL_STUDENT_WAIVER_TYPE');
    if(this.student_fee_types.length <1) this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');

    if (this.wings.length < 1) this.$store.dispatch("GET_ALL_WING");
  },
  watch:{

  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
